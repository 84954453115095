import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import "../stylesheets/general.css"
import "../stylesheets/home.css"
import {
  AppCarousel,
  Button,
  Card,
  Content,
  Hero,
  Layout,
  LogoBox,
  ServiceCard,
  Subscribe,
} from "../components"
import SEO from "../components/SEO"
import { H1, ServiceList } from "../elements"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      mt: imageSharp(fluid: { originalName: { eq: "mt.png" } }) {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid
        }
      }
      bt: imageSharp(fluid: { originalName: { eq: "bt.png" } }) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      xt: imageSharp(fluid: { originalName: { eq: "xt.png" } }) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      heroImage: imageSharp(fluid: { originalName: { eq: "homehero3.png" } }) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)
  const services = [
    {
      abbreviation: "BT",
      description:
        "In a dynamic global environment Organisational Change Management and Digital Transformation strategies are an essential business service to exploit opportunities. Get in touch to learn how our agile and on demand organisational change and transformation strategies can enable your team to: Pivot in crises mode to respond and survive. Change to safeguard, leverage opportunities and create value. Transform to optimise opportunities and sustain value within the new normal.",
      name: "Business Transformation",
      image: data.bt.fluid,
    },
    {
      abbreviation: "MT",
      description:
        "Marketing Transformation is not a trend, it is the start of a new era in which the customer is taking control and challenging the status quo. Fast-paced disruption triggered by digital technology has moved engagement beyond online channels and mobile devices to strategy designed for optimal customer satisfaction and experience. It’s time to go beyond digital competence and embrace transformation. Let us help you move from intent to action to repeatable success and growth.",
      name: "Marketing Transformation",
      image: data.mt.fluid,
    },
    {
      abbreviation: "XT",
      description:
        "Digital technology is shaping the way people shop and discover; consumers are choosing connections over commercials. IPP has invested time and technology mastering the art of positioning your products and services for impressions and impact. Our team has worked with new and well-established brands to transform customer experiences, getting brands into the homes and hearts of customers. We convert fun experiences and exciting engagement into valuable data to drive the development of even more innovative, creative, and meaningful solutions for your customers.",
      name: "Experience Transformation",
      image: data.xt.fluid,
    },
  ]
  return (
    <Layout>
      <SEO title={"Home"} description={"We build seamless digital experiences designed to give winning results."} />
      <Hero
        image={data.heroImage.fluid}
        title="Perfection Is "
        description="We build seamless digital experiences designed to give winning results."
        buttonTitle="Play Video"
        strikeWord="Possible."
        videoUrl="https://itspixelperfect.s3.amazonaws.com/videos/IPP+Website+Loop.mp4"
        videoId="xojcsQNztCw"
        hasBgVideo={true}
      />
      <Content spacing>
        <Card
          name="About Us."
          margin="0 0 82px 0"
          title="The Difference is in the details."
          description="We are It’s Pixel Perfect - IPP to our friends.  Our international team of problem solvers and innovators will help you design and simplify a full suite of digital products and services for transformative results with global impact. We collaborate with brands to transform and inspire using a mix of cutting-edge technology, data-driven solutions and our special brand of marketing magic. The work of our team of thinkers, innovators and executors has been recognised and celebrated by leading Canadian growth accelerator DMZ and the UK’s Branson Centre of Entrepreneurship."
        >
          <Button to="/about">Discover more</Button>
        </Card>
        <Card
          name="Our Clients."
          margin="0 0 82px 0"
          >
          <LogoBox />
        </Card>
        <H1 large>
          Results
          <br /> Driven
        </H1>
      </Content>

      <AppCarousel />
      <Content>
        <Card
          name="Experts In."
          title="Ready to make your brand remarkable?"
          description="IPP’s partners and clients benefit from our knowledge of today’s tactics while collaborating with us to shape the strategies of tomorrow in real time.  A new and bold world requires a new type of team that’s committed to doing it right and measuring the results. Let us help you successfully navigate the potentially disruptive forces of technology, e-commerce and change."
        />
        <ServiceList>
          {services.map((service, index) => (
            <ServiceCard
              toggleButton
              key={index}
              index={index}
              image={service.image}
              name={service.name}
              abbreviation={service.abbreviation}
              description={service.description}
            />
          ))}
        </ServiceList>
      </Content>
      <Subscribe />
    </Layout>
  )
}

export default IndexPage
